<template>
  <TWelcome />
</template>

<script>
import { TWelcome } from "@templates";

export default {
  name: "PHome",
  components: { TWelcome },
};
</script>

<style lang="scss" scoped></style>
